<script>
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";
import { UserIcon, CalendarIcon } from "vue-feather-icons";
import { BitlyClient } from 'bitly';
import { Disqus } from 'vue-disqus';
const bitly = new BitlyClient('905376f55de86f0fcc840d5abc7e664c78c0c8f1', {});
export default {
  extends: BaseVue,
  components: {
    UserIcon,
    CalendarIcon,
    Disqus
  },
  data() {
    return {
      data: {
        an_title: "",
        an_bg: "",
        an_desc: "",
        an_img: "",
      },
      related: [],
      tags: [],
      shortURL: ''
    };
  },
  watch: {
    $route() {
      this.getNewsArticleDetail();
      this.refreshMeta();
    },
  },
  async mounted() {
    this.getNewsArticleDetail();
    this.refreshMeta();
    this.$set(this.$root, "page", this);
    this.urlShortener();
    // setTimeout(() => {
    //   const disquss = document.querySelectorAll('[title="Disqus"]');
    //   disquss.forEach((v, i) => {
    //     if(i == 0 || i == 2){
    //       v.style.display = "none";
    //     }
    //   });
    // }, 1000);

    setTimeout(()=>{
      const iframeDisquss = $("iframe").attr("title");
        const disquss = document.querySelector('[title="Disqus"]');

      if(iframeDisquss == "Disqus"){
        disquss.style.display = "none";
      } else {
        console.log("DAWHDA");
      }
    },3000)
  },
  // computed: {
  //   hiddenDisqus() {
  //     const disquss = document.querySelector('[title="Disqus"]');
  //     console.log(disquss);
  //   }
  // },
  methods: {
    async urlShortener() {
      let result;
      try {
        result = await bitly.shorten(this.currentUrl);
      } catch (e) {
        throw e;
      }
      this.shortURL = result.link;
    },
    getNewsArticleDetail() {
      Gen.apirest(
        "/news-article/detail/" + this.$route.params.slug,
        {},
        (err, resp) => {
          if (resp.code == 404) return this.$router.push({name: 'NewsArticle'});
          if (err) console.log("About goodness error " + err);
          this.data = resp.data;
          this.related = resp.related;
          for (let i = 0; i < this.related.length; i++) {
            this.related[i].an_publish_date = this.dateStrFormat(this.related[i].an_publish_date);
          }

          this.tags = resp.tags;
          this.data.an_publish_date = this.dateStrFormat(this.data.an_publish_date)

          $([document.documentElement, document.body]).animate(
              {
                scrollTop: $(".article-view").offset().top - 150,
              },
              500
          );
        }
      );
    },
    refreshMeta() {
      Gen.rest(this.$route.path, { metainfo: true }, (err, resp) => {
        if (err) return console.error(err);
        
        $("[rel=alternate]").remove()
        $("[rel=canonical]").remove()
        
        if(this.$route.name == "Index"){
          $("head").append('<link rel="canonical" href="https://www.sdi-alhidayah.sch.id">')
          $("[name='robots']").attr('content','index,follow')
          $("[name='googlebot']").attr('content','index,follow')
        } 
        else if(this.$route.name && this.$route.name !== "404"){
          $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="id-id" />`)
          $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="x-default" />`)  
          $("[name='robots']").attr('content','index,follow')
          $("[name='googlebot']").attr('content','index,follow')
        }
        else{
          $("[name='robots']").attr('content','noindex,nofollow')
          $("[name='googlebot']").attr('content','noindex,nofollow')
        }
        
        $("title").text(resp.meta_title);
        $("meta[name='keywords']").attr('content', resp.meta_keyword)
        $("meta[name='description']").attr('content', resp.meta_desc)
        $("meta[name='author']").attr("content", 'SDI Al Hidayah');

        // OG
        $("meta[property='og:image']").attr('content', resp.ss_img)
        $("meta[property='og:title']").attr('content', resp.meta_title)
        $("meta[property='og:keywords']").attr('content', resp.meta_keyword)
        $("meta[property='og:description']").attr('content', resp.meta_desc)
        $("meta[property='og:url']").attr('content', resp.ss_url)
        
        // Twitter
        $("meta[name='twitter:image']").attr('content', resp.ss_img)
        $("meta[name='twitter:title']").attr('content', resp.meta_title)
        $("meta[name='twitter:description']").attr('content', resp.meta_desc)
        $("meta[name='twitter:url']").attr('content', resp.ss_url)
        this.$root.meta = resp;
      });
    },
    shareFB() {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.currentUrl),
        'fbShareWindow', 'top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 -
          225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')
    },
    shareTW() {
      window.open("https://twitter.com/intent/tweet?text=" + encodeURI(this.data.an_title + " -") + "&url=" +
        encodeURI(this.currentUrl), "")
    },
  },
};
</script>
<template>
  <div>
    <section id="content">
      <div class="content-wrap" itemscope itemtype="https://schema.org/Article">
        <section class="section py-sm">
          <div class="container" v-if="data">
            <div class="row">
              <div class="col-lg-8">
                <article class="article-view">
                  <div class="article-view-head">
                    <router-link
                      :to="{
                        name: 'NewsArticleCategory',
                        params: { tipe: data.anc_type == 1 ? 'artikel' : 'berita' },
                      }"
                      :class="[
                        'article-badge',
                        data.anc_type == 1 ? 'color-2' : '',
                      ]"
                    itemprop="articleSection"
                      >{{
                        data.anc_type == 1 ? "Artikel" : "Berita"
                      }}</router-link
                    >
                    <router-link
                      :to="{
                        name: 'NewsArticleCategory',
                        params: { tipe: data.anc_type == 1 ? 'artikel' : 'berita', kategori: data.anc_slug },
                      }"
                      :class="[
                        'article-badge',
                        data.anc_type == 1 ? 'color-2' : '',
                      ]"
                      itemprop="articleSection"
                      >{{ data.anc_category }}</router-link
                    >
                    <h2 itemprop="alternativeHeadline" class="article-view__title">
                      {{ data.an_title }}
                    </h2>
                    <div class="article-head-meta">
                      <div
                        class="row align-items-center justify-content-between"
                      >
                        <div class="col-md-auto">
                          <div class="article-meta-auth">
                            <span itemprop="author" class="article-meta-auth__info d-flex">
                              <div>
                                <user-icon></user-icon> 
                              </div>
                              <div>
                                {{ data.an_author }}
                              </div>
                            </span>
                            <span itemprop="datePublished" class="article-meta-auth__info d-flex">
                              <div>
                                <calendar-icon></calendar-icon>
                              </div>
                              <div>
                                {{ data.an_publish_date }}
                              </div>
                            </span>
                          </div>  
                        </div>
                        <div class="col-md-auto">
                          <div class="article-meta-share">
                            <span>Bagikan ke: </span>
                            <div class="share-icons">
                              <a href="javascript:;" @click="shareFB" class="social-icon si-colored si-small si-rounded si-facebook">
                                <i class="icon-facebook"></i>
                                <i class="icon-facebook"></i>
                              </a>
                              <a href="javascript:;" @click="shareTW" class="social-icon si-colored si-small si-rounded si-twitter">
                                <i class="icon-twitter"></i>
                                <i class="icon-twitter"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <figure class="article-view-media">
                      <img
                        :src="uploader(data.an_img)"
                        :alt="data.an_alt_img"
                        :title="data.an_alt_img"
                        class="article-view-media__img"
                        itemprop="photo"
                      />
                    </figure>
                  </div>
                  <div itemprop="articleBody" class="article-view-body" v-html="data.an_desc"></div>
                  <div class="article-view-body">
                    <h4 itemprop="alternativeHeadline">Tags</h4>
                    <router-link v-for="(v, k) in tags" :key="k" 
                      :to="{ name: 'NewsArticleTags', params: { tags: v.ant_slug}}">
                      <button type="button" class="btn btn-sm btn-outline-success mr-2 mb-2">{{v.ant_tags}}</button>
                    </router-link>
                  </div>
                  <div class="article-view-body">
                    <section itemprop="comment" class='comments' aria-labelledby="comment">
                      <h4 itemprop="comment">Comments</h4>
                      <Disqus shortname='https-beta-sdi-alhidayah-sch-id-1' />
                    </section>
                  </div>
                </article>
              </div>
              <aside class="col-lg-4">
                <div class="side-list">
                  <h3 itemprop="alternativeHeadline" class="side-list__title">
                    Berita dan Artikel Terkait
                  </h3>
                  <p v-show="related.length === 0">
                    Tidak ada berita dan artikel terkait.
                  </p>
                  <div
                    class="side-article"
                    v-for="(item, index) in related"
                    :key="index"
                  >
                    <div class="row gutter-16 col-mb-32">
                      <div class="col-5">
                        <router-link
                          :to="{
                            name: 'NewsArticleDetail',
                            params: { slug: item.an_slug },
                          }"
                          class="side-article-media"
                        >
                          <img
                            :src="uploader(item.an_img)"
                            :alt="item.an_alt_img"
                            :title="item.an_alt_img"
                            class="side-article__img"
                            itemprop="thumbnailUrl"
                          />
                        </router-link>
                      </div>
                      <div class="col-7">
                        <div class="side-article-meta">
                          <div class="side-article-badges">
                             <router-link
                              :to="{
                                name: 'NewsArticleCategory',
                                params: { tipe: 'artikel' },
                              }"
                              v-if="item.anc_type == '1'"
                              class="side-article__badge article-badge color-2"
                              itemprop="articleSection"
                              >Artikel</router-link
                            >
                            <router-link
                              :to="{
                                name: 'NewsArticleCategory',
                                params: { tipe: 'berita' },
                              }"
                              v-else-if="item.anc_type == '2'"
                              class="side-article__badge article-badge"
                              itemprop="articleSection"
                              >Berita</router-link
                            >
                            <router-link
                              :to="{
                                name: 'NewsArticleCategory',
                                params: { tipe: item.anc_type == '1' ? 'artikel' : 'berita', kategori: item.anc_slug },
                              }"
                              :class="[
                                'side-article__badge',
                                'article-badge',
                                item.anc_type == '1' ? 'color-2' : '',
                              ]"
                              itemprop="articleSection"
                              >{{ item.anc_category }}</router-link
                            >
                          </div>
                          <div class="side-article-content">
                            <h4 itemprop="name" class="side-article__title">
                              <router-link
                                :to="{
                                  name: '',
                                  params: { slug: item.an_slug },
                                }"
                                v-tooltip="
                                  item.an_title.limitChar(46).endsWith('...')
                                    ? item.an_title
                                    : ''
                                "
                                >{{ item.an_title.limitChar(46) }}</router-link
                              >
                            </h4>
                            <span class="side-article__auth"
                              ><user-icon></user-icon>
                              {{ item.an_author }}</span
                            >
                            <span class="side-article__auth"
                              ><calendar-icon></calendar-icon>
                              {{ item.an_publish_date }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
          <div class="container" v-else>
            <div class="notfound">
              <div class="row justify-content-center">
                <div class="col-auto">
                  <img
                    :src="assets('fo_images', '404.svg')"
                    alt="Halaman Tidak Ditemukan"
                    title="Halaman Tidak Ditemukan"
                    class="notfound__img"
                  />
                </div>
                <div class="w-100"></div>
                <div class="col-lg-6 col-md-8">
                  <div class="notfound-meta text-center">
                    <h2 class="notfound-meta__title">
                      Kami Tidak Dapat Menemukan Halaman yang Anda Cari
                    </h2>
                    <router-link
                      :to="{ name: 'NewsArticle' }"
                      class="cta cta--primary"
                      >Daftar Berita &amp; Artikel</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!-- #content end -->
  </div>
</template>
